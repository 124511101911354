/* eslint-disable no-undef */
import { memo, useEffect } from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'

import SmavaLandingPage from '../../../components/page/SmavaLandingPage'
import FinanzcheckLandingPage from '../../../components/page/FinanzcheckLandingPage'

import theme from '../../../theme'
import { isSmava } from '../../../utils/getTenantInfo.utils'
import { initPollardCommunication } from '../../../utils/pollard'
import useUrlParams from '../../../hooks/useUrlParams'
import { getAdvertisementId, getRefId } from '../../../utils/url'
import { getSessionId } from '../../../utils/session.utils'
import { triggerHubbleTracking } from '../../../utils/hubble/tracking'

const Base = () => {
  const queryParams = useUrlParams()
  const advertisementId = getAdvertisementId(queryParams)
  const refId = getRefId(queryParams)

  useEffect(
    () => {
      // Temporay, should be removed once proxing of old Smava route
      // to embed route is configured
      initPollardCommunication()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    const fcId = getSessionId()

    ;(async function () {
      triggerHubbleTracking('visit', {
        fcId,
        ...queryParams,
        advertisementId,
        refId,
        src: 'antrag',
      })
    })()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isSmava ? <SmavaLandingPage /> : <FinanzcheckLandingPage />}
    </ThemeProvider>
  )
}

export default memo(Base)
